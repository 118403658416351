import React, {useEffect, useState} from 'react';
import {useForm} from "@mantine/form";
import {Group, Input, InputWrapper, LoadingOverlay, Select, Stepper, Text} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import style from "./Auth.module.css";
import {FaceIdError, Logout, Ship} from "tabler-icons-react";
import {ReactComponent as ArrowIcon} from "../../assets/svg/arrow.svg";
import {ButtonCustom} from "../../components";
import {
    ILogin,
    useGetAllShipQuery,
    useGetCruiseQuery,
    useLoginMutation
} from "../../redux/apiSlice/_authSlice";
import {ICruise, IShip} from "../../types/IAuth";
import {showNotification} from "@mantine/notifications";
import {SHIP} from "../../configs/api.config";

const Auth = () => {
    const token = localStorage.getItem("token");
    const [active, setActive] = useState(token ? 1 : 0);
    const [ship, setShip] = useState<string>("");
    const [cruise, setCruise] = useState<string>("");
    const navigate = useNavigate();

    const {data = [], error: errorShip} = useGetAllShipQuery(active);
    const shipData = data.map((s: IShip) => ({value: String(s.shipId), label: s.name}));

    const shipName = shipData.find((t: any) => t.value == ship)?.label || "";

    const {data: cruises = [], error: errorCruise} = useGetCruiseQuery(ship);
    const cruiseData = cruises.map((s: ICruise) => ({
        value: String(s.cruiseId),
        label: `${shipName} ${s.dateStart} - ${s.dateEnd}`
    }));

    const [login, {isSuccess, data: loginData, isLoading, isError}] = useLoginMutation();


    const {onSubmit, getInputProps} = useForm<ILogin>({
        initialValues: {
            login: "",
            password: ""
        }
    });


    const handleLogin = async (model: ILogin) => {
        await login(model);
    };

    const handleSetShip = () => {
        navigate(`/request`);
        localStorage.setItem("ship", ship);
        localStorage.setItem("cruise", cruise);
        localStorage.setItem("shipName", shipData.find((t: any) => t.value == ship)?.label || "");

        const cruiseName = cruises.find((t) => t.cruiseId.toString() === cruise)

        localStorage.setItem("cruiseName", `${cruiseName?.dateStart} - ${cruiseName?.dateEnd}`);

    };

    useEffect(() => {
        if (isSuccess && loginData) {
            localStorage.setItem("token", loginData.token);
            setActive(1);
        }
    }, [isSuccess, loginData]);

    useEffect(() => {
        if (active) {
            setShip(SHIP);
        }
    }, [active])

    useEffect(() => {
        if (isError) {
            showNotification({
                title: "Ошибка",
                message: "Неверный логин или пароль",
                color: 'red',
                icon: <FaceIdError/>,
            });
        }
    }, [isError]);

    useEffect(() => {
        if (!active) return;
        if (!!errorCruise && 'status' in errorCruise) {
            if (errorCruise.status == 401) {
                localStorage.clear();
                return setActive(0);
            }
        }
        if (!!errorShip && 'status' in errorShip) {
            if (errorShip.status == 401) {
                localStorage.clear();
                return setActive(0);
            }
        }
    }, [errorCruise, errorShip])
    
    return (
        <Group position="center" className={style.container}>
            <Stepper active={active} onStepClick={setActive}>
                <Stepper.Step icon={<Logout size={18}/>} disabled>
                    <form onSubmit={onSubmit((handleLogin))}>
                        <Group className={style.content}>
                            <LoadingOverlay visible={isLoading}/>
                            <Text className={style.title}>Авторизация</Text>
                            <Group direction="column" className={style.form}>
                                <InputWrapper style={{width: "100%"}}
                                              label="Логин"
                                >
                                    <Input variant="filled"
                                           error
                                           placeholder="Введите логин"
                                           {...getInputProps("login")}
                                    />
                                </InputWrapper>
                                <InputWrapper style={{width: "100%"}} label="Пароль">
                                    <Input variant="filled"
                                           placeholder="Введите пароль"
                                           {...getInputProps("password")}
                                    />
                                </InputWrapper>
                            </Group>
                            <Group position="right" className={style.button}>
                                <ButtonCustom width="md" appearance="secondary" type="submit">
                                    Войти
                                </ButtonCustom>
                            </Group>
                        </Group>
                    </form>
                </Stepper.Step>
                <Stepper.Step icon={<Ship size={18}/>} disabled>
                    <Group className={style.content}>
                        <Text className={style.title}>Выберите круиз</Text>
                        <Group direction="row" className={style.form}>
                            {/*<Select*/}
                            {/*    rightSection={<ArrowIcon/>}*/}
                            {/*    variant="filled"*/}
                            {/*    label="Корабль"*/}
                            {/*    placeholder="Выбрать корабль"*/}
                            {/*    styles={{*/}
                            {/*        rightSection: {pointerEvents: 'none'},*/}
                            {/*    }}*/}
                            {/*    value={ship}*/}
                            {/*    onChange={(event: string) => setShip(event)}*/}
                            {/*    data={shipData || []}*/}
                            {/*/>*/}
                            <Select
                                rightSection={<ArrowIcon/>}
                                label="Круиз"
                                placeholder="Выбрать круиз"
                                variant="filled"
                                style={{
                                    width: "100%",
                                }}
                                styles={{
                                    rightSection: {pointerEvents: 'none'},
                                }}
                                value={cruise}
                                onChange={(e: string) => setCruise(e)}
                                data={cruiseData || []}
                            />
                        </Group>
                        <Group position="right" className={style.button}>
                            <ButtonCustom width="md"
                                          appearance="secondary"
                                          onClick={handleSetShip}
                                          disabled={(!ship || !cruise)}
                            >
                                Продолжить
                            </ButtonCustom>
                        </Group>
                    </Group>
                </Stepper.Step>
            </Stepper>
        </Group>
    );
};

export default Auth;